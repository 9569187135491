<template>
  <div class="content">
    <div class="word-card">
      <v-card
        class="rounded-lg card"
        height="calc(100% - 1.8rem)"
        elevation="0"
        v-touch="{
          up: swipeUp,
          down: swipeDown,
        }"
      >
        <div v-show="false">
          <audio
            ref="pronounce"
            v-if="!isEmpty(current) && current.audio"
            preload="auto"
            :src="current.audio"
          />
        </div>
        <div class="cover">
          <error-page
            :offLine="offline"
            @refresh="refresh"
            v-if="isEmpty(current) && !isLoading"
          ></error-page>
          <loading-page :isLoading="isLoading"></loading-page>
          <div class="tips" v-show="!isEmpty(current)">
            <span>请根据单词说出中文意思<br /></span>
            <span style="color: #a7b1b2">然后选择是否认识</span>
          </div>
          <v-btn
            v-show="!isEmpty(current)"
            fab
            x-small
            absolute
            style="top: 1rem; right: 1rem"
            elevation="1"
            @click="showDeleteDialog"
          >
            <v-icon color="#808080">mdi-delete</v-icon>
          </v-btn>
          <div class="word-title" v-if="!isEmpty(current) && current.word">
            {{ current.word }}
          </div>
          <div class="phonetic" v-if="!isEmpty(current) && current.phonetic">
            <van-tag class="tag" size="large"
              >/ {{ current.phonetic }} /
            </van-tag>
          </div>
          <v-btn
            v-show="!isEmpty(current) && current.audio"
            class="mt-10"
            fab
            plain
            small
            elevation="0"
            color="#ffffff"
            :disabled="isPlaying"
            @click="playAudio"
          >
            <v-icon color="blue-grey darken-2">mdi-account-voice</v-icon>
          </v-btn>
        </div>

        <v-expand-transition>
          <v-card
            elevation="0"
            v-if="reveal"
            class="transition-fast-in-fast-out v-card--reveal rounded-lg"
            style="height: 100%"
          >
            <div class="word-detail">
              <div class="word-title mt-10 mb-5">{{ current.word }}</div>
              <div
                class="phonetic"
                v-show="current.phonetic"
                style="margin-left: -0.5px"
              >
                <van-tag class="tag" size="large"
                  >/ {{ current.phonetic }} /
                </van-tag>
                <v-btn
                  class="ml-3"
                  fab
                  plain
                  x-small
                  elevation="0"
                  color="#ffffff"
                  :disabled="isPlaying"
                  @click="playAudio"
                >
                  <v-icon color="blue-grey darken-2">mdi-account-voice</v-icon>
                </v-btn>
              </div>
              <v-divider></v-divider>
              <div
                class="transation"
                v-show="current.translation"
                v-html="formatTranslate(current.translation)"
              ></div>
              <v-btn
                outlined
                class="mt-10"
                small
                @click="goDictionary"
                style="display: flex"
                color="#909090"
              >
                在词典中查看 —&gt;
              </v-btn>
              <v-btn
                outlined
                class="mt-3"
                small
                color="teal accent-4"
                @click="reCheck"
              >
                选错了 &lt;—
              </v-btn>
            </div>
            <div class="smile-cry">
              <img
                v-show="currentType === 1"
                class="left-img"
                :src="require('@/assets/svgimgs/learnWord/smile.svg')"
                alt="smile"
              />
              <img
                v-show="currentType === 0"
                class="right-img"
                :src="require('@/assets/svgimgs/learnWord/cry.svg')"
                alt="cry"
              />
            </div>
          </v-card>
        </v-expand-transition>
      </v-card>
    </div>

    <div class="btn-all rounded-lg" v-show="!isEmpty(current)">
      <transition name="btn">
        <div v-show="!reveal" class="btn-gp rounded-lg">
          <v-btn
            large
            elevation="1"
            width="45%"
            height="100%"
            class="rounded-lg"
            color="#6ec5ab"
            dark
            @click="remember"
          >
            <img
              style="margin-left: -1rem; width: 2.5rem; height: 2.5rem"
              :src="require('@/assets/svgimgs/learnWord/smile.svg')"
            />{{ firstMeet ? "认识" : "记得" }}
          </v-btn>
          <v-btn
            large
            elevation="1"
            width="45%"
            height="100%"
            class="rounded-lg"
            color="#a1a1a1"
            dark
            @click="forget"
          >
            <img
              style="margin-left: -1rem; width: 2.5rem; height: 2.5rem"
              :src="require('@/assets/svgimgs/learnWord/cry.svg')"
            />{{ firstMeet ? "陌生" : "忘记" }}
          </v-btn>
        </div>
      </transition>
      <transition name="btn">
        <div v-show="reveal" class="btn-gp rounded-lg">
          <v-btn
            large
            elevation="1"
            width="100%"
            height="3.1rem"
            class="rounded-lg"
            color="#ffffff"
            @click="nextWord"
            :loading="nextLoading"
            >下一个单词 —&gt;
            <template v-slot:loader>
              云同步中
              <span class="ml-1 custom-loader">
                <v-icon light>mdi-cached</v-icon>
              </span>
            </template>
          </v-btn>
        </div>
      </transition>
    </div>
    <v-dialog v-model="deleteConfirmDialog" persistent max-width="290">
      <v-card>
        <v-card-title class="text-h5"> 将此单词标记为熟知？</v-card-title>
        <v-card-text>标记熟知后，将不再出现在单词复习中。</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="green darken-1"
            text
            @click="deleteConfirmDialog = false"
          >
            取消
          </v-btn>
          <v-btn
            color="green darken-1"
            text
            @click="confirmDelete"
            :loading="deleteLoading"
          >
            确认移除
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { learnWord } from "@/mixin/learnWordMixin";

export default {
  name: "learnNew",
  components: {},
  mixins: [learnWord],
  data: () => ({
    type: 1, // 1新词，0复习
  }),
  methods: {},
};
</script>

<style lang="scss" scoped>
@import "../style/style";
</style>
